.hover {
  opacity: 0.5;
}

.hover:hover {
  opacity: 1;
}

.hover-parent:hover > .hover,
.hover-active .hover {
  opacity: 1;
}

.contact-img {
  margin-bottom: -48px;
}

.bg-wave {
  background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.blur {
  filter: blur(3px);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
   * supported by Chrome, Edge, Opera and Firefox */
}

/* top-left border-radius */

table tr:first-child th:first-child {
  border-top-left-radius: 0.5rem !important;
}

table tr:first-child th:last-child {
  border-top-right-radius: 0.5rem !important;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem !important;
}

/* top-right border-radius */

/* bottom-left border-radius */

/* bottom-right border-radius */

.text-python {
  color: #3FB422;
}

.text-python-hl {
  color: #acc267;
}

.text-hl {
  color: #f92672;
}

.text-hl-yellow {
  color: #FBBF24;
}

.caption {
  color: #869ab8 !important;
  font-size: 12px;
}

.highlight-block {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.25);
}

.highlight-block pre,
.highlight-block table {
  background: #1e1e1e;
  color: white;
}

pre {
  overflow: auto;
  -ms-word-wrap: normal;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: pre;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Handle on hover */

.bg-default {
  background-image: url("~/media/imgs/illustrations/linkdb-left.png"), url("~/media/imgs/illustrations/linkdb-right.png");
}

.bg-usa {
  background-image: url("~/media/imgs/illustrations/linkdb-left-usa.png"), url("~/media/imgs/illustrations/linkdb-right-usa.png");
}

.bg-france {
  background-image: url("~/media/imgs/illustrations/linkdb-left-france.png"), url("~/media/imgs/illustrations/linkdb-right-france.png");
}

.bg-india {
  background-image: url("~/media/imgs/illustrations/linkdb-left-india.png"), url("~/media/imgs/illustrations/linkdb-right-india.png");
}

.bg-spain {
  background-image: url("~/media/imgs/illustrations/linkdb-left-spain.png"), url("~/media/imgs/illustrations/linkdb-right-spain.png");
}

.bg-sweden {
  background-image: url("~/media/imgs/illustrations/linkdb-left-sweden.png"), url("~/media/imgs/illustrations/linkdb-right-sweden.png");
}